import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import BackgroundImage from "gatsby-background-image"

const Header = ({ siteTitle, logo, pdf }) => {
  return (
    <header>
      <nav id="menu" className="bg-brown u-1-of-1">
        <BackgroundImage
          Tag="a"
          href="/"
          className="logo u-ml- u-mr"
          fixed={logo.childImageSharp.fixed}
          alt="Dan Garland's Initials"
        >
          Home
        </BackgroundImage>

        <a className="l-block burger u-mr" href="#menu" />
        <a className="l-block close-burger u-mr" href="#" />

        <ul className="o-list-bare o-list-inline u-m0 u-p0">
          <li className="o-list-inline__item u-pr  u-pr">
            <Link className="link" to="/blog">
              Blog
            </Link>
          </li>
          <li className="o-list-inline__item u-pr  u-pr">
            <Link className="link" to="/about-me">
              About Me
            </Link>
          </li>
          <li className="o-list-inline__item u-pr  u-pr">
            <Link className="link" to="/experience">
              Experience
            </Link>
          </li>
          <li className="o-list-inline__item u-pr  u-pr">
            <Link className="link" to="/hire-me">
              Hire Me
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
