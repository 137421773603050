/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "./header"
import "../css/main.scss"
import "prismjs/themes/prism-solarizedlight.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      freeagent: file(relativePath: { eq: "social/freeagent.png" }) {
        ...socialIcon
      }
      github: file(relativePath: { eq: "social/github.png" }) {
        ...socialIcon
      }
      linkedin: file(relativePath: { eq: "social/linkedin.png" }) {
        ...socialIcon
      }
      stack_overflow: file(relativePath: { eq: "social/stack_overflow.png" }) {
        ...socialIcon
      }
      twitter: file(relativePath: { eq: "social/twitter.png" }) {
        ...socialIcon
      }
      logo: file(relativePath: { eq: "logos/dg.png" }) {
        childImageSharp {
          fixed(width: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      pdf: file(relativePath: { eq: "icons/pdf.png" }) {
        childImageSharp {
          fixed(width: 16, height: 16) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        logo={data.logo}
        pdf={data.pdf}
      />

      <main id="main" className="o-wrapper c-black l-centered" role="main">
        {children}
      </main>

      <footer
        id="contact"
        className="bg-black u-1-of-1 b-top--solid b-top--thick b-brown c-white t-center u-pb++ u-pt+"
      >
        <div className="o-wrapper">
          <ul className="o-list-bare o-line-inline u-mb+ u-p0">
            <li className="o-list-inline__item u-pr- u-mb-">
              <Link to="/" className="link inverted t-bold c-white t-dec-none">
                Home
              </Link>
            </li>
            <li className="o-list-inline__item u-pr- u-pl- u-mb-">
              <Link
                className="link inverted t-bold c-white t-dec-none"
                to="/blog"
              >
                Blog
              </Link>
            </li>
            <li className="o-list-inline__item u-pr- u-pl- u-mb-">
              <Link
                className="link inverted t-bold c-white t-dec-none"
                to="/about-me"
              >
                About Me
              </Link>
            </li>
            <li className="o-list-inline__item u-pr- u-pl- u-mb-">
              <Link
                className="link inverted t-bold c-white t-dec-none"
                to="/experience"
              >
                Experience
              </Link>
            </li>
            <li className="o-list-inline__item u-pr- u-pl- u-mb-">
              <Link
                className="link inverted t-bold c-white t-dec-none"
                to="/hire-me"
              >
                Hire Me
              </Link>
            </li>
          </ul>

          <div id="social" className="o-layout o-layout--center">
            <ul>
              <li className="o-layout__item u-1-of-6-tablet u-1-of-3-mobile u-pl0 u-mb">
                <a
                  className="l-centered"
                  href="http://www.freeagent.com/?referrer=35p107mb"
                  target="_blank"
                  title="10% Discount with FreeAgent!"
                >
                  <BackgroundImage
                    fixed={data.freeagent.childImageSharp.fixed}
                    alt="Freeagent"
                  />
                  Free Agent
                </a>
              </li>
              <li className="o-layout__item u-1-of-6-tablet u-1-of-3-mobile u-pl0 u-mb">
                <a
                  className="l-centered"
                  href="http://stackoverflow.com/users/875138/dan-garland"
                  target="_blank"
                >
                  <BackgroundImage
                    fixed={data.stack_overflow.childImageSharp.fixed}
                    alt="Stack Overflow"
                  />
                  Stack Overflow
                </a>
              </li>
              <li className="o-layout__item u-1-of-6-tablet u-1-of-3-mobile u-pl0 u-mb">
                <a
                  className="l-centered"
                  href="https://github.com/dmgarland"
                  target="_blank"
                >
                  <BackgroundImage
                    fixed={data.github.childImageSharp.fixed}
                    alt="Github"
                  />
                  Github
                </a>
              </li>
              <li className="o-layout__item u-1-of-6-tablet u-1-of-3-mobile u-pl0 u-mb">
                <a
                  className="l-centered"
                  href="http://www.twitter.com/dmgarland"
                  target="_blank"
                >
                  <BackgroundImage
                    fixed={data.twitter.childImageSharp.fixed}
                    alt="Twitter"
                  />
                  Twitter
                </a>
              </li>
              <li className="o-layout__item u-1-of-6-tablet u-1-of-3-mobile u-pl0 u-mb">
                <a
                  className="l-centered"
                  href="http://www.linkedin.com/in/dmgarland"
                  target="_blank"
                >
                  <BackgroundImage
                    fixed={data.linkedin.childImageSharp.fixed}
                    alt="LinkedIn"
                  />
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="copyright u-pt">
          <small>&#169; Dan Garland 2020</small>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export const socialIcon = graphql`
  fragment socialIcon on File {
    childImageSharp {
      fixed(width: 48, height: 48, quality: 100) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export default Layout
